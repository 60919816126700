import React from "react";
import Maze from "./maze";
import Headline from "./headline";
import HeroMouse from "./heroMouse";
import useFlags from "../useFlags";
import "./hero.css";

const Hero = () => {
  const { show_maze, show_typewriter } = useFlags();
  return (
    <section className="hero">
      {show_maze && <Maze />}
      {show_typewriter && <Headline />}
      <HeroMouse />
    </section>
  );
};

export default Hero;
