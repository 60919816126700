import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import useTranslations from "../useTranslations";
import useComponents from "../useComponents";
import AnimateSubTitle from "../Title/animateSubTitle";
import AnimateTitle from "../Title/animateTitle";
import "./portfolio.css";
import Carousel from "./carousel";
import { useLocale } from "../../hooks/locale";

const Portfolio = () => {
  const { locale } = useLocale();
  const data = useStaticQuery(query);
  const rawData = data.rawData;
  const translate = data.translate;
  const allMdx = data[locale];
  const { portfolio } = useComponents(rawData);
  const { see_more_details } = useTranslations(translate);

  return (
    <>
      <section className="container section__margin">
        <AnimateTitle
          items={portfolio.title}
          className="section__headline col-12 mb-3"
        />
        <AnimateSubTitle
          items={portfolio.sub_title}
          className="section__sub col-lg-8 col-12 mb-5"
        />
        <Carousel allMdx={allMdx} translate={see_more_details} />
      </section>
    </>
  );
};

export default Portfolio;

const query = graphql`
  query portfolioQuery {
    rawData: allFile(filter: { sourceInstanceName: { eq: "components" } }) {
      edges {
        node {
          name
          translations: childComponentsJson {
            portfolio {
              sub_title {
                text
                className
              }
              title {
                className
                text
              }
            }
          }
        }
      }
    }
    translate: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            see_more_details
          }
        }
      }
    }
    pl: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/(case_studies)/.*.mdx$/" }
        fields: { locale: { eq: "pl" } }
      }
      limit: 4
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 80
                  formats: [WEBP]
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    en: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/(case_studies)/.*.mdx$/" }
        fields: { locale: { eq: "en" } }
      }
      limit: 4
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  quality: 80
                  formats: [WEBP]
                )
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
