import React, { Component } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "./portfolio.css";
import Btn from "../Buttons/btn";

import LocalizedLink from "../LocalizedLink";

class Carousel extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      focusOnSelect: false,
    };

    return (
      <div className="container mt-5">
        <Slider {...settings}>
          {this.props.allMdx.edges.map(({ node }, index) => {
            return (
              <div key={index} className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-12 portfolio__img">
                    <GatsbyImage
                      imgClassName="mx-auto d-block"
                      image={getImage(node.frontmatter.image)}
                      className=""
                      alt={node.frontmatter.title + index}
                    />
                  </div>
                  <div className="col-lg-5 col-12">
                    <div>
                      <div className="portfolio__detail-head">
                        <LocalizedLink
                          to={node.fields.slug}
                          type="case study"
                          aria-label={node.frontmatter.title}
                        >
                          <h4>{node.frontmatter.title}</h4>
                        </LocalizedLink>
                      </div>
                    </div>
                    <div className="portfolio__detail-spec mt-3">
                      {node.frontmatter.description}
                    </div>
                    <div className="portfolio__btn mt-3">
                      <Btn
                        linkClass="btn-style-1"
                        type="case study"
                        link={node.fields.slug}
                        name={node.frontmatter.title}
                      >
                        {this.props.translate}
                      </Btn>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default Carousel;
