import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
import "./hero.css";

class Cell {
  constructor(cellType) {
    this.value = cellType;
    this.row = null;
    this.col = null;
    this.distance = null;
    this.pred = null;
    this.bfsColor = "transparent";
    this.direction = 0;
  }

  getColor = function () {
    if (this.value === "#") {
      return "#cecece";
    } else if (this.value === " ") {
      return "transparent";
    } else if (this.value === "S") {
      return "#008000";
    } else if (this.value === "E") {
      return "#FF0000";
    } else if (this.value === "P") {
      return "#ffcb00";
    } else {
      return "#ffcb00";
    }
  };

  setPos = function (row, col) {
    this.row = row;
    this.col = col;
  };

  getNeighbors = function () {
    // Not necessarily legal neighbors
    var row = this.row;
    var col = this.col;
    return [
      [row - 1, col - 1],
      [row - 1, col],
      [row - 1, col + 1],
      [row, col - 1],
      [row, col + 1],
      [row + 1, col - 1],
      [row + 1, col],
      [row + 1, col + 1],
    ];
  };

  setDistance = function (distance) {
    this.distance = distance;
  };

  getDistance = function () {
    return this.distance;
  };

  getBFSColor = function () {
    return this.bfsColor;
  };

  setBFSColor = function (color) {
    this.bfsColor = color;
  };

  setBFSColor = function (color) {
    this.bfsColor = color;
  };

  setPred = function (pred) {
    this.pred = pred;
  };

  getPred = function () {
    return this.pred;
  };
}

class Maze {
  constructor(ctx, canvas) {
    this.contents = [];
    this.start = null;
    this.end = null;
    this.ctx = ctx;
    this.canvas = canvas;
  }

  initContents = function (desiredRes) {
    for (let i = 0; i < desiredRes; i++) {
      this.contents.push([]);
      for (let j = 0; j < desiredRes; j++) {
        if (
          i === 0 ||
          i === desiredRes - 1 ||
          j === 0 ||
          j === desiredRes - 1
        ) {
          let cell = new Cell("#");
          cell.setPos(i, j);
          this.contents[i].push(cell);
        } else {
          let cell = new Cell(" ");
          cell.setPos(i, j);
          this.contents[i].push(cell);
        }
      }
    }
  };

  generator = function ([x1, x2], [y1, y2], desiredRes) {
    let width = x2 - x1;
    let height = y2 - y1;
    if (width >= height) {
      // vertical bisection
      if (x2 - x1 > 3) {
        let bisection = Math.ceil((x1 + x2) / 2);
        let max = y2 - 1;
        let min = y1 + 1;
        let randomPassage = Math.floor(Math.random() * (max - min + 1)) + min;
        let first = false;
        let second = false;
        if (this.contents[y2][bisection].value === " ") {
          randomPassage = max;
          first = true;
        }
        if (this.contents[y1][bisection].value === " ") {
          randomPassage = min;
          second = true;
        }
        for (let i = y1 + 1; i < y2; i++) {
          if (first && second) {
            if (i === max || i === min) {
              continue;
            }
          } else if (i === randomPassage) {
            continue;
          }
          this.contents[i][bisection].value = "#";
        }
        this.generator([x1, bisection], [y1, y2], desiredRes);
        this.generator([bisection, x2], [y1, y2], desiredRes);
      }
    } else {
      // horizontal bisection
      if (y2 - y1 > 3) {
        let bisection = Math.ceil((y1 + y2) / 2);
        let max = x2 - 1;
        let min = x1 + 1;
        let randomPassage = Math.floor(Math.random() * (max - min + 1)) + min;
        let first = false;
        let second = false;
        if (this.contents[bisection][x2].value === " ") {
          randomPassage = max;
          first = true;
        }
        if (this.contents[bisection][x1].value === " ") {
          randomPassage = min;
          second = true;
        }
        for (let i = x1 + 1; i < x2; i++) {
          if (first && second) {
            if (i === max || i === min) {
              continue;
            }
          } else if (i === randomPassage) {
            continue;
          }
          this.contents[bisection][i].value = "#";
        }
        this.generator([x1, x2], [y1, bisection], desiredRes);
        this.generator([x1, x2], [bisection, y2], desiredRes);
      }
    }
  };

  render = function () {
    if (this.canvas) {
      let width = window.innerWidth;
      if (width <= 600) {
        this.canvas.width = 260;
        this.canvas.height = 260;
      } else if (width <= 1100) {
        if (window.innerHeight >= 705) {
          this.canvas.width = 475;
          this.canvas.height = 475;
        } else if (window.innerHeight >= 625) {
          this.canvas.width = 400;
          this.canvas.height = 400;
        } else {
          this.canvas.width = 260;
          this.canvas.height = 260;
        }
      } else {
        if (window.innerHeight >= 705) {
          this.canvas.width = 475;
          this.canvas.height = 475;
        } else if (window.innerHeight >= 625) {
          this.canvas.width = 400;
          this.canvas.height = 400;
        } else {
          this.canvas.width = 260;
          this.canvas.height = 260;
        }
      }

      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
      let numRows = this.contents.length;
      let numCols = this.contents[0].length;
      let cellWidth = this.ctx.canvas.width / numCols;
      let cellHeight = this.ctx.canvas.height / numRows;
      let half = cellWidth / 2;
      let cellLength = cellWidth > cellHeight ? cellHeight : cellWidth;
      for (let row = 0; row < numRows; row++) {
        for (let col = 0; col < numCols; col++) {
          let cell = this.contents[row][col];
          if (cell.value === "S") {
            this.ctx.fillStyle = cell.getColor();
            let rectX = col * cellLength;
            let rectY = row * cellLength;
            this.ctx.fillRect(rectX, rectY, cellLength, cellLength);
            this.ctx.stroke();
          } else if (cell.value === "E") {
            this.ctx.fillStyle = cell.getColor();
            let rectX = col * cellLength;
            let rectY = row * cellLength;
            this.ctx.fillRect(rectX, rectY, cellLength, cellLength);
          }
          this.ctx.strokeStyle = "#000";
          let sideLateral = col === 0 || col === numCols - 1;
          let topOrBottom = row === 0 || row === numRows - 1;
          let v1 = col > 0 ? this.contents[row][col - 1].value !== "#" : true;
          let v2 =
            col + 1 < numCols
              ? this.contents[row][col + 1].value !== "#"
              : true;
          let isVertical = v1 && v2 && this.contents[row][col].value === "#";
          let h1 = row > 0 ? this.contents[row - 1][col].value !== "#" : true;
          let h2 =
            row + 1 < numRows
              ? this.contents[row + 1][col].value !== "#"
              : true;
          let isHorizontal = h1 && h2 && this.contents[row][col].value === "#";
          if (row === 0 && col === 0) {
            this.ctx.beginPath();
            this.ctx.moveTo(half, half);
            this.ctx.lineTo(cellWidth, half);
            this.ctx.stroke();
          } else if (row === 0 && col === numCols - 1) {
            this.ctx.beginPath();
            this.ctx.moveTo(col * cellWidth, half);
            this.ctx.lineTo(col * cellWidth + half, half);
            this.ctx.stroke();
          } else if (row === numRows - 1 && col === 0) {
            this.ctx.beginPath();
            this.ctx.moveTo(col * cellWidth + half, row * cellWidth);
            this.ctx.lineTo(col * cellWidth + half, row * cellWidth + half);
            this.ctx.stroke();
          } else if (row === numRows - 1 && col === numCols - 1) {
            this.ctx.beginPath();
            this.ctx.moveTo(col * cellWidth + half, row * cellWidth);
            this.ctx.lineTo(col * cellWidth + half, row * cellWidth + half);
            this.ctx.stroke();
          } else {
            if (sideLateral || isVertical) {
              let lift =
                (row === 1 && (col !== 0 || col !== numCols - 1)) ||
                this.contents[row - 1][col].value === "#";
              let lower =
                (row === numRows - 2 && (col !== 0 || col !== numCols - 1)) ||
                this.contents[row + 1][col].value === "#";
              let offsetTop = lift ? half : -half;
              let offsetBottom = lower ? half : -half;
              this.ctx.beginPath();
              this.ctx.moveTo(
                col * cellWidth + half,
                row * cellWidth - offsetTop
              );
              this.ctx.lineTo(
                col * cellWidth + half,
                row * cellWidth + cellWidth + offsetBottom
              );
              this.ctx.stroke();
            } else if (topOrBottom || isHorizontal) {
              let toLeft =
                col !== 0 && this.contents[row][col - 1].value === "#";
              let toRight =
                col !== numCols - 1 &&
                this.contents[row][col + 1].value === "#";
              let offsetLeft = toLeft ? half : -half;
              let offsetRight = toRight ? half : -half;
              this.ctx.beginPath();
              this.ctx.moveTo(
                col * cellWidth - offsetLeft,
                row * cellWidth + half
              );
              this.ctx.lineTo(
                col * cellWidth + cellWidth + offsetRight,
                row * cellWidth + half
              );
              this.ctx.stroke();
            }
          }
        }
      }
    }
  };

  addBends = function (currentCell) {
    const currentRow = currentCell.row;
    const currentColumn = currentCell.col;
    if (currentCell.getPred()) {
      const nextCell = currentCell.getPred();
      const nextRow = nextCell.row;
      const nextColumn = nextCell.col;

      /**
       *  F - first cell
       *  S - second cell
       *  x - cell that we consider in first if
       */

      //   F | x
      // .. | S
      if (nextRow > currentRow && nextColumn > currentColumn) {
        if (this.contents[currentRow][nextColumn].value === " ") {
          this.addConnectingCell(currentRow, nextColumn, currentCell, 1);
        } else if (this.contents[currentRow][nextColumn].value === "#") {
          this.addConnectingCell(
            nextRow - 1,
            currentColumn + 1,
            currentCell,
            3
          );
        }
      }

      //  .. | F
      //  S | x
      if (nextRow > currentRow && nextColumn < currentColumn) {
        if (this.contents[nextRow][currentColumn].value === " ") {
          this.addConnectingCell(nextRow, currentColumn, currentCell, 4);
        } else if (this.contents[nextRow][currentColumn].value === "#") {
          this.addConnectingCell(
            currentRow + 1,
            nextColumn + 1,
            currentCell,
            2
          );
        }
      }

      //  S | x
      // .. | F
      if (nextRow < currentRow && nextColumn < currentColumn) {
        if (this.contents[currentRow][nextColumn].value === " ") {
          this.addConnectingCell(
            currentRow - 1,
            nextColumn + 1,
            currentCell,
            3
          );
        } else if (this.contents[currentRow][nextColumn].value === "#") {
          this.addConnectingCell(nextRow, currentColumn, currentCell, 1);
        }
      }

      //   x | S
      //   F | ..
      if (nextRow < currentRow && nextColumn > currentColumn) {
        if (this.contents[currentRow][nextColumn].value === " ") {
          this.addConnectingCell(currentRow, nextColumn, currentCell, 4);
        } else if (this.contents[currentRow][nextColumn].value === "#") {
          this.addConnectingCell(
            nextRow + 1,
            currentColumn + 1,
            currentCell,
            2
          );
        }
      }
    }
  };

  deleteUnwantedDirectionChange = function (currentCell) {
    const nextCell = currentCell.getPred();
    const nextnextCell = nextCell.getPred();
    if (nextnextCell) {
      if (
        currentCell.direction === 0 &&
        nextnextCell.direction === 0 &&
        nextCell.direction !== 0
      ) {
        if (
          (currentCell.row === nextCell.row &&
            nextCell.row === nextnextCell.row) ||
          (currentCell.col === nextCell.col &&
            nextCell.col === nextnextCell.col)
        ) {
          nextCell.direction = 0;
        }
      }
    }
  };

  addConnectingCell = function (row, column, currentCell, direction) {
    const connectingCell = this.contents[row][column];
    if (currentCell.getPred() !== connectingCell) {
      const currentCellPred = currentCell.getPred();
      currentCell.setPred(connectingCell);
      connectingCell.setPred(currentCellPred);
      connectingCell.direction = direction; // the way particular quarter of circle looks
    }
  };

  drawQuarterCircle = function (cx, cy, radius, direction, cellWidth) {
    let cyCorrection;
    // to draw properly each quarter in some cases we start from different position
    switch (direction) {
      case 1:
      case 3:
        cyCorrection = cy + cellWidth;
        break;

      case 2:
      case 4:
      default:
        cyCorrection = cy;
        break;
    }
    const startAngle = ((4 - direction) * Math.PI) / 2;
    const endAngle = startAngle + Math.PI / 2;
    this.ctx.beginPath();
    this.ctx.moveTo(cx, cyCorrection);
    this.ctx.arc(cx, cyCorrection, radius, startAngle, endAngle);
    this.ctx.closePath();
    this.ctx.fill();
    this.ctx.strokeStyle = "transparent";
    this.ctx.stroke();
  };

  renderCell = function (cell) {
    if (this.canvas) {
      let numRows = this.contents.length;
      let numCols = this.contents[0].length;
      let cellWidth = this.ctx.canvas.width / numCols;
      let cellHeight = this.ctx.canvas.height / numRows;
      let cellLength = cellWidth > cellHeight ? cellHeight : cellWidth;
      this.ctx.fillStyle = cell.getColor();
      let rectX = cell.col * cellLength;
      let rectY = cell.row * cellLength;
      let radius = Math.sqrt(cellWidth ** 2, cellHeight ** 2);
      if (cell.direction) {
        this.drawQuarterCircle(
          rectX,
          rectY,
          radius,
          cell.direction,
          cellLength
        );
      } else {
        this.ctx.fillRect(rectX, rectY, cellLength, cellLength);
      }
    }
  };

  drawBeginningOfLine = function () {
    const firstCell = this.start.getPred();
    let newCell;
    if (this.start.row !== firstCell.row && this.start.col !== firstCell.col) {
      if (this.contents[this.start.row][firstCell.col] === " ") {
        newCell = this.contents[this.start.row][firstCell.col];
      } else {
        newCell = this.contents[firstCell.row][this.start.col];
      }
      newCell.setPred(this.start.getPred());
      this.start.setPred(newCell);
    }
  };

  getEmptySlots = function () {
    let emptySlots = [];
    for (let row = 0; row < this.contents.length; row++) {
      for (let col = 0; col < this.contents[0].length; col++) {
        if (this.contents[row][col].value === " ") {
          emptySlots.push(this.contents[row][col]);
        }
      }
    }
    return emptySlots;
  };

  initPoints = function () {
    let emptySlots = this.getEmptySlots();
    if (emptySlots.length > 1) {
      this.start = emptySlots[0];
      this.end = emptySlots[emptySlots.length - 1];
      this.start.value = "S";
      this.end.value = "E";
    }
  };

  shortestBFS = function () {
    let start = this.end;
    start.setDistance(0);
    start.setPred(null);
    let cellQueue = []; // enqueue is push - dequeue is shift
    cellQueue.push(start);
    while (cellQueue.length > 0) {
      let currentCell = cellQueue.shift();
      let neighbors = currentCell.getNeighbors();
      for (let neighbor of neighbors) {
        let row = neighbor[0];
        let col = neighbor[1];
        if (
          row >= 0 &&
          col >= 0 &&
          row < this.contents.length &&
          col < this.contents[0].length
        ) {
          let cell = this.contents[row][col];
          if (cell.getBFSColor() === "transparent" && cell.value !== "#") {
            cell.setBFSColor("gray");
            cell.setDistance(currentCell.getDistance() + 1);
            cell.setPred(currentCell);
            cellQueue.push(cell);
          }
        }
      }
      currentCell.setBFSColor("black");
    }
  };

  bfsTraverse = function (currentCell) {
    currentCell.value = "P";
    this.renderCell(currentCell);
  };

  clearSolution = function () {
    for (let row of this.contents) {
      for (let element of row) {
        if (element.value === "P") {
          element.value = " ";
        }
      }
    }
  };
}

const Canvas = () => {
  const canvasRef = useRef(null);
  const [distanceTop, setDistanceTop] = useState(0);
  const [iconRLeft, setIconRLeft] = useState(0);
  const [iconLRight, setIconLRight] = useState(0);
  const [iconLLeft, setIconLLeft] = useState(0);
  const [display, setDisplay] = useState("none");

  let genSize = null; //= setGenSize();
  let myMaze = null;
  let animationRequest = null;
  let currentWindowWidth = null;
  let currentWindowHeight = null;
  let nextWindowWidth = null;
  let nextWindowHeight = null;
  let cell = null;

  function generator() {
    myMaze = new Maze(canvasRef.current.getContext("2d"), canvasRef.current);
    myMaze.initContents(genSize);
    myMaze.generator([0, genSize - 1], [0, genSize - 1], genSize);
    myMaze.initPoints();
    myMaze.render();
  }

  function animate() {
    var steps = 1;
    while (steps-- >= 0 && cell !== myMaze.end) {
      myMaze.bfsTraverse(cell);
      cell = cell.getPred();
    }
    if (cell !== myMaze.end) {
      animationRequest = requestAnimationFrame(animate);
    }
  }

  function setupMaze() {
    genSize = setGenSize();
    generator();

    myMaze.shortestBFS();
    myMaze.drawBeginningOfLine();
    var firstCell = myMaze.start.getPred();

    cell = firstCell;

    while (cell !== myMaze.end) {
      myMaze.addBends(cell);
      cell = cell.getPred();
    }
    cell = firstCell;

    while (cell !== myMaze.end) {
      myMaze.deleteUnwantedDirectionChange(cell);
      cell = cell.getPred();
    }

    cell = firstCell;

    animationRequest = requestAnimationFrame(animate);
  }

  function setGenSize() {
    let genSize;
    let width = window.innerWidth;
    if (width <= 600) {
      genSize = 52;
    } else if (width <= 1100) {
      if (window.innerHeight >= 705) {
        genSize = 95;
      } else if (window.innerHeight >= 625) {
        genSize = 80;
      } else {
        genSize = 52;
      }
    } else {
      if (window.innerHeight >= 705) {
        genSize = 95;
      } else if (window.innerHeight >= 625) {
        genSize = 80;
      } else {
        genSize = 52;
      }
    }
    return genSize;
  }

  function setIconsPosition() {
    if (canvasRef.current) {
      if (window.innerWidth >= 1001) {
        setSelectedPositions(
          `calc(${canvasRef.current.offsetHeight}px + 15vh)`,
          `5vw`,
          "",
          `8vw`
        );
      } else {
        setSelectedPositions(
          `calc(${canvasRef.current.offsetHeight}px + 10vh)`,
          `calc(50vw - ${canvasRef.current.offsetWidth}px / 2)`,
          `calc(50vw - ${canvasRef.current.offsetWidth}px / 2 + 4vh)`,
          ""
        );
      }
    }
  }

  function handleResize() {
    if (currentWindowHeight === null || currentWindowWidth === null) {
      currentWindowWidth = window.innerWidth;
      currentWindowHeight = window.innerHeight;
    }
    currentWindowWidth = nextWindowWidth;
    nextWindowWidth = window.innerWidth;
    const minWidth = Math.min(currentWindowWidth, nextWindowWidth);
    const maxWidth = Math.max(currentWindowWidth, nextWindowWidth);
    const minHeight = Math.min(currentWindowHeight, nextWindowHeight);
    const maxHeight = Math.max(currentWindowHeight, nextWindowHeight);

    // generate new maze only when maze size changes
    if (
      (minWidth <= 600 && maxWidth >= 600) ||
      (minWidth <= 1100 && maxWidth >= 1100) ||
      (minHeight <= 625 && maxHeight >= 625) ||
      (minHeight <= 705 && maxHeight >= 705)
    ) {
      ///   genSize = setGenSize();
      window.cancelAnimationFrame(animationRequest);
      // animationRequest = null;
      setupMaze();
      setIconsPosition();
    }
  }

  function setSelectedPositions(top, rLeft, lRight, lLeft) {
    setDistanceTop(top);
    setIconRLeft(rLeft);
    setIconLRight(lRight);
    setIconLLeft(lLeft);
    setDisplay("block");
  }

  function handleClick() {
    // genSize = setGenSize();
    setupMaze();
  }

  useEffect(() => {
    function init() {
      setupMaze();
      setIconsPosition();
      window.addEventListener("resize", handleResize);
    }
    init();

    return () => {
      window.cancelAnimationFrame(animationRequest);
      animationRequest = null;
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      <canvas id="maze" ref={canvasRef}></canvas>
      <FontAwesomeIcon
        className="icon__maze"
        icon={faSyncAlt}
        style={{
          display: "block",
          left: iconRLeft,
          top: distanceTop,
        }}
        onClick={handleClick}
      />
      <Link
        to="/#"
        style={{
          display: display,
          left: iconLLeft,
          position: "absolute",
          right: iconLRight,
          top: distanceTop,
        }}
        aria-label={"lightbulb"}
      >
        <FontAwesomeIcon
          style={{
            display: "none",
          }}
          icon={faLightbulb}
          className={`icon__maze`}
        />
      </Link>
    </>
  );
};

export default Canvas;
