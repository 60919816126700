import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import useTranslations from "../useTranslations";
import Typewriter from "typewriter-effect";
import { useLocale } from "../../hooks/locale";
import "./hero.css";

const Headline = () => {
  const { locale } = useLocale();
  const { translate } = useStaticQuery(query);
  const { create, hit_the_nail_on_head, websites, applications, programs } =
    useTranslations(translate);
  return (
    <div className="hero__title">
      <div className="hero__thin">
        {create}
        <Typewriter
          options={{
            strings: [programs, websites, applications],
            autoStart: true,
            loop: true,
          }}
        />
      </div>
      {locale === "pl" && (
        <div className="hero__fat">{hit_the_nail_on_head}</div>
      )}
    </div>
  );
};

export default Headline;

const query = graphql`
  query Headline {
    translate: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            create
            hit_the_nail_on_head
            websites
            applications
            programs
          }
        }
      }
    }
  }
`;
