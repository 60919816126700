import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Content from "./content";
import AnimateTitle from "../Title/animateTitle";
import useComponents from "../useComponents";

const Offer = () => {
  const { rawData } = useStaticQuery(query);
  const { offer } = useComponents(rawData);

  return (
    <section className="container section__margin">
      <AnimateTitle
        items={offer.title}
        className="col-12 text-center mb-5"
      ></AnimateTitle>
      <Content content={offer.content} />
    </section>
  );
};

export default Offer;

export const query = graphql`
  query Offer {
    rawData: allFile(filter: { sourceInstanceName: { eq: "components" } }) {
      edges {
        node {
          name
          translations: childComponentsJson {
            offer {
              content {
                title
                thumbnail {
                  className
                }
                items {
                  link
                  text
                }
              }
              title {
                className
                text
              }
            }
          }
        }
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: "/(components)/offer.*.mdx$/" } }
    ) {
      edges {
        node {
          fields {
            locale
          }
          body
        }
      }
    }
  }
`;
