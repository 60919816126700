import React from "react";
import { Link } from "gatsby";
import "./offer.css";

const Content = (props) => {
  return (
    <div className="row justify-content-center mt-2 text-center">
      {props.content.map((el) => {
        return (
          <div
            key={el.title}
            className="col-lg-4 col-lx-4 col-md-6 col-12 offer-list__content"
          >
            <h3>{el.title}</h3>
            <div className={`img__resposive ${el.thumbnail.className}`}></div>
            <ul>
              {el.items.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.link}>{item.text}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
};

export default Content;
