import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import useOtherLocations from "../useOtherLocations";
import AnimateTitle from "../Title/animateTitle";
import useComponents from "../useComponents";
import Btn from "../Buttons/btn";

const Contact = () => {
  const { rawData } = useStaticQuery(query);
  const { contact } = useComponents(rawData);
  const items = useOtherLocations();
  const matchItem = items.filter((item) => item.name === "contact")[0];

  return (
    <section className="container section__margin mb-5">
      <AnimateTitle
        className="section__headline col-12 mb-3"
        items={contact.title}
      />
      <div className="row mt-5">
        <p className="col-lg-8">
          {contact.content.map((item, index) => {
            return (
              <span key={index}>
                {item.text} {index < contact.content.length - 1 ? <br /> : null}
              </span>
            );
          })}
        </p>
        <div className=" col-lg-4">
          {!matchItem.exclude && (
            <Btn
              name={matchItem.title}
              link={matchItem.link}
              className="btn btn-style-1"
            >
              <span>{matchItem.title.toUpperCase()}</span>
            </Btn>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;

const query = graphql`
  query contactQuery {
    rawData: allFile(filter: { sourceInstanceName: { eq: "components" } }) {
      edges {
        node {
          name
          translations: childComponentsJson {
            contact {
              title {
                text
                className
              }
              content {
                text
              }
            }
          }
        }
      }
    }
  }
`;
