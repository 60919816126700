import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Btn from "../Buttons/btn";
import useOtherLocations from "../useOtherLocations";
import useComponents from "../useComponents";
import AnimateTitle from "../Title/animateTitle";

const ProjectExamples = () => {
  const { rawData } = useStaticQuery(query);
  const items = useOtherLocations();
  const button = items.filter((item) => item.name === "offer_check")[0];
  const { project_examples } = useComponents(rawData);

  return (
    <section className="container section__margin">
      <AnimateTitle
        items={project_examples.title}
        className="col-12 text-center mb-5"
      />
      {!button.exclude && (
        <div className="row justify-content-center">
          <Btn name={button.title} link={button.link} className="btn-style-1">
            <span className="f-light">{button.title.toUpperCase()}</span>
          </Btn>
        </div>
      )}
    </section>
  );
};

export default ProjectExamples;

const query = graphql`
  query project {
    rawData: allFile(filter: { sourceInstanceName: { eq: "components" } }) {
      edges {
        node {
          name
          translations: childComponentsJson {
            project_examples {
              title {
                className
                text
              }
            }
          }
        }
      }
    }
  }
`;
