import React from "react";
import scrollToY from "../../functions/functions";
import "./hero.css";

const HeroMouse = () => {
  const handleClick = () => {
    const scrollTargetY = window.innerHeight;
    const speed = 3000;
    const easing = "easeInOutQuint";
    scrollToY(scrollTargetY, speed, easing);
  };
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => null}
      className="hero__mouse"
      aria-label="hero mouse"
      onClick={handleClick}
    ></div>
  );
};

export default HeroMouse;
