import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ServiceList from "./serviceList";
import AnimateSubTitle from "../Title/animateSubTitle";
import AnimateTitle from "../Title/animateTitle";
import useComponents from "../useComponents";
import "./service.css";

const Service = () => {
  const { rawData } = useStaticQuery(query);
  const { service } = useComponents(rawData);
  return (
    <section className="container section__margin ">
      <AnimateTitle items={service.title} className="col-12 text-center mb-3" />
      <AnimateSubTitle
        items={service.sub_title}
        className="col-12 text-center mb-5"
      />
      <div className="row">
        <ServiceList content={service.content} />
      </div>
    </section>
  );
};

export default Service;

const query = graphql`
  query service {
    rawData: allFile(filter: { sourceInstanceName: { eq: "components" } }) {
      edges {
        node {
          name
          translations: childComponentsJson {
            service {
              title {
                text
                className
              }
              sub_title {
                text
                className
              }
              content {
                title
                thumbnail {
                  className
                }
                description
              }
            }
          }
        }
      }
    }
  }
`;
