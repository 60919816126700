import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import Seo from "../components/seo";
import Hero from "../components/Hero/hero";
import Offer from "../components/Offer/offer";
import ProjectExamples from "../components/ProjectExamples/projectExample";
import Service from "../components/Service/service";
import Stats from "../components/Stats/stats";
import Portfolio from "../components/Portfolio/portfolio";
import Map from "../components/Map/map";
import Clients from "../components/Clients/clients";
import Contact from "../components/Contact/contact";
import config from "../../config/i18n";
import redirects from "../../config/redirects";
import useFlags from "../components/useFlags";

const IndexPage = ({ pageContext, location, path }) => {
  const {
    show_offer,
    show_project_example,
    show_service,
    show_stats,
    show_portfolio,
    show_map,
    show_clients,
    show_contact,
  } = useFlags();

  useBreadcrumb({
    location,
    crumbLabel: "Home",
    crumbPosition: 1,
  });

  useEffect(() => {
    redirects.forEach((redirect) =>
      redirect.fromPath === path ? navigate(redirect.toPath) : null
    );
  }, [path]);

  return (
    <>
      <Seo
        title={config[pageContext.locale].defaultTitle}
        description={config[pageContext.locale].defaultDescription}
        pathname={path}
      />
      <main>
        <Hero />
        {show_offer && <Offer />}
        {show_project_example && <ProjectExamples />}
        {show_service && <Service />}
        {show_stats && <Stats />}
        {show_portfolio && <Portfolio />}
        {show_map && <Map />}
        {show_clients && <Clients />}
        {show_contact && <Contact />}
      </main>
    </>
  );
};

export default IndexPage;
